@font-face {
    font-family: 'FamiliarPro';
    src: url('../fonts/FamiliarPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FamiliarPro-Regular.woff') format('woff'),
        url('../fonts/FamiliarPro-Regular.ttf') format('truetype'),
        url('../fonts/FamiliarPro-Regular.svg#FamiliarPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'FamiliarPro';
    src: url('../fonts/FamiliarPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FamiliarPro-Bold.woff') format('woff'),
        url('../fonts/FamiliarPro-Bold.ttf') format('truetype'),
        url('../fonts/FamiliarPro-Bold.svg#FamiliarPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FamiliarPro';
    src: url('../fonts/FamiliarPro-Light.eot');
    src: url('../fonts/FamiliarPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FamiliarPro-Light.woff2') format('woff2'),
        url('../fonts/FamiliarPro-Light.woff') format('woff'),
        url('../fonts/FamiliarPro-Light.ttf') format('truetype'),
        url('../fonts/FamiliarPro-Light.svg#FamiliarPro-Light') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Larke Neue';
    src: url('../fonts/LarkeNeue-Regular.eot');
    src: url('../fonts/LarkeNeue-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LarkeNeue-Regular.woff2') format('woff2'),
        url('../fonts/LarkeNeue-Regular.woff') format('woff'),
        url('../fonts/LarkeNeue-Regular.ttf') format('truetype'),
        url('../fonts/LarkeNeue-Regular.svg#LarkeNeue-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    box-sizing: border-box;
}

$tra-app-color-green: #7fbc03;
$tra-app-color-red: #D0112B;
$tra-app-color-grey-dark: #474747;
$tra-app-color-grey-medium: #a3a3a3;
$tra-app-color-grey-light: #f5f5f5;


html,
body {
    /* workaround to hide scroll */
    height: calc(100% - 15px);
    width: 100%;
    margin: 0;
    padding: 0;
    background: #fff;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
}


.heat {

    .row-filter.row-filter-search-address {
        display: none;
    }
}

.heat,
.choropleth,
.socialMedia {
    .row-filter-isp {
        margin-left: auto;

        @media screen and (max-width: 768px) {
            margin-left: 0;
        }
    }
}

.social-media-only {
    display: none;
}

.socialMedia .social-media-only {
    display: block;
}

.relative {
    position: relative;
}

.container {
    // padding: 0 5px;
    position: relative;
}


.h1 {
    margin: 0 -15px 15px -15px;
    padding: 5px 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    background: $tra-app-color-red;
    color: #fff;
}

iframe {
    width: 100%;
    border: none;
}

.row-btns {
    display: flex;
    text-align: center;
    margin-top: 10px;
    padding: 0 40px;
    font-family: 'Larke Neue';
    font-weight: 400;


    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }
}

.btn {
    outline: none;
    border: none;
    background: #fff;
    color: $tra-app-color-grey-dark;
    font-size: 24px;
    cursor: pointer;
    padding: 10px 5px;
    transition: all ease 0.2s;
    border-radius: 10px;
}

.btn-filter {
    display: inline-block;
    width: 30%;
    max-width: 270px;
    margin-bottom: -11px;
    padding: 13px 0 17px 0;
    background: $tra-app-color-grey-light;
    font-size: 18px;
    color: $tra-app-color-grey-medium;
    text-align: center;
    text-decoration: none;
    border: none;
    clip-path: polygon(0 0, 100% 0%, calc(100% - 1px) 100%, 0px calc(100% - 15px));
    border-radius: 18px 18px 0 0;
    transform: rotate(-2.7deg);
    cursor: pointer;

    .btn-filter-text {
        display: inline-block;
        transform: rotate(2.7deg);
    }

    +.btn-filter {
        margin-left: 16px;
    }

    @media screen and (max-width: 768px) {
        width: 50%;
        margin-bottom: -11px;
        font-size: 14px;
    }
}


.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
    color: #fff;
    background: $tra-app-color-green;
    text-decoration: none;
}

.btn.btn-fullscreen {
    display: none;
    margin-left: auto;
    padding-left: 40px;
    font-family: 'FamiliarPro';
    font-weight: 700;
    font-size: 16px;
    color: $tra-app-color-grey-medium;
    background-image: url('../assets/icon-fullscreen.svg');
    background-size: 18px;
    background-position: 11px center;
    background-repeat: no-repeat;

    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: transparent;
    }

    &.active {
        background-image: url('../assets/icon-exit-fullscreen.svg');
    }
}

.btn.btn-toggle-form {
    display: none;
    position: relative;
    margin: 0 auto;
    padding: 15px 15px 15px 28px;
    font-size: 12px;
    font-weight: 700;
    font-family: 'FamiliarPro';
    color: $tra-app-color-grey-medium;
    text-align: center;
    background-color: transparent;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 50%;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url('../assets/icon-chevron-down.svg');
        background-size: contain;
        background-repeat: no-repeat;
        transform: translateY(-50%) rotate(180deg);
        content: "";
    }

    &.collapsed {
        margin-bottom: 6px;
        padding: 4px 15px 4px 28px;

        &::before {
            transform: translateY(-50%) rotate(0deg);
        }
    }

    @media screen and (max-width: 768px) {
        display: block;
    }
}

#map {
    height: 100%;
    min-height: 250px;
}

.form-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 13px 40px 5px 40px;
    background: $tra-app-color-grey-light;
    font-family: 'Larke Neue';
    font-weight: 400;

    @media screen and (max-width: 768px) {
        padding: 13px 15px 5px 15px;
    }
}

.form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-end;
    font-size: 14px;

    &.collapsed {
        @media screen and (max-width: 768px) {
            height: 0;
            overflow: hidden;
        }
    }
}

.row-filter {
    width: 100%;
    margin-right: 8px;

    @media screen and (max-width: 768px) {
        margin-right: 0;
    }
}

.row-filter-isp,
.row-filter-month-picker {
    width: auto;

    @media screen and (max-width: 768px) {
        width: calc(50% - 4px);
        margin-top: 16px;
    }
}


.row-filter-month-picker {
    @media screen and (max-width: 768px) {
        margin-left: auto;
    }
}

.row-filter.row-filter-search-address {
    width: auto;

    @media screen and (min-width: 1200px) {
        width: 53%;
    }
}

.row-filter-access-type {
    @media screen and (max-width: 768px) {
        width: auto;
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: 16px;
    }
}

#accessType {
    max-height: 62px;

    @media screen and (max-width: 768px) {
        max-width: 128px;
        margin-right: 8px;
        justify-content: space-between;
    }
}

.filter-title {
    display: block;
    margin-bottom: 4px;
    font-family: 'FamiliarPro';
    font-size: 14px;
    font-weight: 700;
    color: $tra-app-color-grey-medium;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.visibility-hidden {
    display: none;
}

/* Customize the label (the label) */
.label-radio-filter {
    display: inline-block;
    position: relative;
    width: 60px;
    min-height: 42px;
    margin: 0 4px 0 0;
    font-size: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default radio button */

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~.checkmark {
            color: #fff;
            background-color: $tra-app-color-green;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 15px 16px 15px 57px;
        border: 1px solid #E2E2E2;
        background-color: #fff;
        color: $tra-app-color-grey-dark;
        border-radius: 6px;
        text-align: left;
        text-transform: capitalize;
        z-index: 1;

        &:after {
            top: calc(50% - 1px);
            left: 50%;
            transform: translate(-50%, -50%);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &:hover {
        .checkmark {
            color: #fff;
            background-color: $tra-app-color-green;

            &:after {
                color: #fff;
            }
        }

        &.both {
            .checkmark {
                &:after {
                    background-image: url('../assets/icon-signal-white.svg');
                }
            }
        }

        &.wifi {
            .checkmark {
                &:after {
                    background-image: url('../assets/icon-wifi-white.svg');
                }
            }
        }

        &.cellular {
            .checkmark {
                &:after {
                    background-image: url('../assets/icon-cellular-white.svg');
                }
            }
        }
    }

    &.both {
        .checkmark {
            padding-left: 59px;

            &:after {
                left: 26px;
                width: 24px;
                height: 24px;
                background-image: url('../assets/icon-signal-red.svg');
            }
        }

        input {
            &:checked~.checkmark {
                &:after {
                    background-image: url('../assets/icon-signal-white.svg');
                }
            }
        }
    }

    &.wifi {
        .checkmark {
            padding: 0;

            &:after {
                width: 24px;
                height: 24px;
                background-image: url('../assets/icon-wifi-red.svg');
            }
        }

        input {
            &:checked~.checkmark {
                &:after {
                    background-image: url('../assets/icon-wifi-white.svg');
                }
            }
        }
    }

    &.cellular {
        .checkmark {
            padding: 0;

            &:after {
                width: 24px;
                height: 24px;
                background-image: url('../assets/icon-cellular-red.svg');
            }
        }

        input {
            &:checked~.checkmark {
                &:after {
                    background-image: url('../assets/icon-cellular-white.svg');
                }
            }
        }
    }

    &.access-item {
        width: auto;

        .checkmark {
            position: relative;
            padding: 11px 10px;
            justify-content: center;
        }

        &.access-item-left {
            margin-right: -6px;

            .checkmark {
                border-radius: 6px 0 0 6px;
            }
        }

        &.access-item-right {
            .checkmark {
                border-radius: 0 6px 6px 0;
            }
        }
    }

    &.small {
        width: 66px;

        .checkmark {
            padding: 12px;
            justify-content: center;
        }
    }


    @media screen and (max-width: 768px) {
        margin: 0;
        font-size: 14px;


        &.both {
            .checkmark {
                padding-left: 50px;

                &:after {
                    left: 19px;
                }
            }
        }


        &.cellular {
            .checkmark {
                padding-left: 39px;
            }
        }
    }
}


.hidden {
    display: none !important;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
}


.label-radio-filter input:checked~.checkmark:after {
    color: '#fff'
}




/* The container must be positioned relative: */
.custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
}

.custom-select select {
    display: none;
    /*hide original SELECT element: */
}

/* Style the arrow inside the select element: */
.select-selected:after {
    display: block;
    position: absolute;
    right: 3px;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    transform: translateY(-50%);
    border-color: #fafafa transparent transparent transparent;
    content: " ";
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    transform: rotate(180deg);
    top: 40%;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 9999;
}

.select-selected[data-icon-social-media],
.select-items [data-icon-social-media] {
    position: relative;
    padding-left: 56px;

    &::before {
        display: block;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
    }
}

[data-icon-social-media="facebook"] {
    &::before {
        width: 24px;
        height: 24px;
        background-image: url('../assets/icon-facebook.png');
    }
}

[data-icon-social-media="youtube"] {
    &::before {
        width: 24px;
        height: 24px;
        background-image: url('../assets/icon-youtube.png');
    }
}

[data-icon-social-media="snapchat"] {
    &::before {
        width: 24px;
        height: 24px;
        background-image: url('../assets/icon-snapchat.png');
    }
}

[data-icon-social-media="instagram"] {
    &::before {
        width: 24px;
        height: 24px;
        background-image: url('../assets/icon-instagram.png');
    }
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover {
    background-color: $tra-app-color-grey-light;
}




/* Month picker styles */
.custom-month-picker {
    display: inline-block;
    width: 100%;
}

.select-selected,
.month-picker-trigger,
.input-address {
    position: relative;
    display: inline-block;
    min-height: 42px;
    outline: none;
    border: none;
    color: $tra-app-color-grey-dark;
    text-align: left;
    background: #fff;
    border-radius: 6px;
    padding: 8px 16px;
}

.select-selected,
.month-picker-trigger {
    width: 200px;
    border: 1px solid #E2E2E2;
    cursor: pointer;

    &::after {
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        width: 13px;
        height: 7px;
        transform: translateY(-50%);
        background-image: url('../assets/icon-arrow-bottom-red.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        content: " ";
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}


.custom-month-picker input {
    display: none;
}

.month-picker-select {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    padding: 45px 0 0;
    border-radius: 5px;
    background: #fff;
    z-index: 9999;
    overflow-y: scroll;

    &.active {
        display: block;
    }
}


.month-picker-select.active~.month-picker-trigger::after {
    transform: rotate(180deg);
    top: 40%;
}

.select-items div,
.select-selected,
.month-picker-option {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 15px;
    cursor: pointer;
}

.select-items div,
.month-picker-option {

    &:hover,
    &.active {
        background: $tra-app-color-grey-light;
    }
}

.month-picker-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 23px;
    height: 23px;


    &:before,
    &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 100%;
        width: 1px;
        background-color: $tra-app-color-grey-dark;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

/* Mobiles md screen */
@media screen and (min-width: 560px) {
    .row-filter {
        width: 50%;
    }
}

/* Mobiles lg sreens, Tablets, Desktops */
@media screen and (min-width: 768px) {

    .container {
        // padding: 0 15px;
    }

    .row-filter {
        width: auto;
    }

    .form {
        // justify-content: space-around;
    }

    .visibility-hidden {
        display: block;
        visibility: hidden;
    }

    .month-picker-close {
        display: none;
    }

    .month-picker-select {
        width: 100%;
        height: auto;
        position: absolute;
        right: 0;
        top: 100%;
        padding-top: 0;
        overflow: hidden;
    }
}

@media screen and (min-width: 1200px) {
    .btn.btn-fullscreen {
        display: block;
    }
}

/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 500px;
    text-align: center;
    font-size: 18px;
    /* Could be more or less, depending on screen size */
    animation-name: animatetop;
    animation-duration: 0.4s;
}

/* The Close Button */
.modal .close {
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 28px;
    font-weight: bold;
}

.modal .close:hover,
.modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Modal Body */
.modal-body {
    padding: 2px 16px;
}

/* Add Animation */
@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}
